<template>
  <button
    @click="open = true"
    class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:opacity-75 transition-all ease-linear md:py-4 md:text-lg md:px-10"
  >
    Contact
  </button>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-slate-600 bg-opacity-80 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-slate-50 rounded-md px-8 pb-8 pt-2 text-left overflow-hidden transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h2"
                  class="mint-ask text-3xl cera-bold leading-6 text-slate-600"
                >
                  Contact
                </DialogTitle>
                <div class="my-3 text-primary text-lg font-medium">
                  Send me a message and I'll get back to you as soon as
                  possible!
                </div>
              </div>
            </div>
            <div class="bg-slate-50 py-8 px-4 sm:px-6 lg:col-span-3 lg:px-8">
              <div class="max-w-lg mx-auto lg:max-w-none">
                <form
                  ref="form"
                  @submit.prevent="sendEmail"
                  class="grid grid-cols-1 gap-y-6"
                >
                  <div>
                    <label for="name" class="sr-only">Full name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autocomplete="name"
                      class="block w-full py-3 px-4 placeholder-gray-500 border focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                      placeholder="Full name"
                    />
                  </div>
                  <div>
                    <label for="email" class="sr-only">Email</label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      class="block w-full py-3 px-4 placeholder-gray-500 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <label for="phone" class="sr-only">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autocomplete="tel"
                      class="block w-full py-3 px-4 placeholder-gray-500 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                      placeholder="Phone"
                    />
                  </div>
                  <div>
                    <label for="subject" class="sr-only">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      autocomplete="subject"
                      class="block w-full py-3 px-4 placeholder-gray-500 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                      placeholder="Subject"
                    />
                  </div>
                  <div>
                    <label for="message" class="sr-only">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      class="block w-full py-3 px-4 placeholder-gray-500 focus:ring-primary focus:border-primary border border-gray-300 rounded-md"
                      placeholder="Message"
                    />
                  </div>
                  <div class="py-4 flex justify-center w-full">
                    <vue-recaptcha
                      sitekey="6Lf6GHYgAAAAAJR-SPjgLZM2crTPupS-DszYwVxW"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      class="w-full inline-flex justify-center py-3 px-6 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:opacity-75 transition-all ease-linear"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import emailjs from "@emailjs/browser";
import { VueRecaptcha } from "vue-recaptcha";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";

export default {
  name: "ContactBtn",
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    VueRecaptcha,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_bkpxjlj",
          "template_n4ic49i",
          this.$refs.form,
          "GkqnaeM9KOD6tMW_j"
        )
        .then(
          (result) => {
            console.log("success", result.text);
            this.$moshaToast("Message sent successfully!", {
              showIcon: true,
              position: "top-center",
              timeout: 4000,
              type: "success",
              toastBackgroundColor: "#2DD4BF",
              transition: "bounce",
              hideProgressBar: true,
            });
            this.open = false;
          },
          (error) => {
            console.log("failed", error.text);
            this.$moshaToast("Something went wrong, try again later!", {
              showIcon: true,
              position: "top-center",
              timeout: 4000,
              type: "danger",
              toastBackgroundColor: "#f43f5e",
              transition: "bounce",
              hideProgressBar: true,
            });
            this.open = false;
          }
        );
    },
  },
};
</script>

<style></style>
