<template>
  <router-view class="h-full"></router-view>
</template>

<style>
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h1 {
  font-family: "Roboto-Black", sans-serif;
}
h2 {
  font-family: "Roboto-Bold", sans-serif;
}
h3 {
  font-family: "Roboto-Regular", sans-serif;
}
@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"),
    url(./fonts/Roboto/Roboto-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(./fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url(./fonts/Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url(./fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Thin";
  src: local("Roboto-Thin"),
    url(./fonts/Roboto/Roboto-Thin.ttf) format("truetype");
}
</style>
